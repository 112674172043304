//---------------------------------------------------------//
// Navigation
//---------------------------------------------------------//
// - All Menus
//---------------------------------------------------------//

.nav {
    list-style: none;
    padding-left: 0;
}

// Topnav

.topnav {
    margin: 0;
    font-weight: bold;
    margin-left: -5px;
    margin-right: -5px;
    li {
        padding-left: 5px;
        padding-right: 5px;
        display: inline-block;
        vertical-align: middle;
    }
}

// Navbar Nav

.navbar-nav {
    font-weight: bold;
    margin: 0;
    display: none;
    > li {
        margin-right: 18px;
        padding-bottom: 15px;
        padding-top: 15px;
        &:last-child {
            margin-right: 0;
        }
    }
    > li > a {
        padding-left: 2px;
        padding-right: 2px;
        border-bottom: 3px solid transparent;
        padding-top: 10px;
        padding-bottom: 5px;
        line-height: 1;
        display: block;
        &:hover,
        &:focus,
        &.active {
            color: $primary;
            border-color: $primary;
            text-decoration: none;
        }
    }
    @include media-breakpoint-up(md) {
        display: flex;
    }
    @include media-breakpoint-up(lg) {
        > li {
            margin-right: 30px;
        }
    }
    @include media-breakpoint-up(xl) {
        > li {
            margin-right: 45px;
        }
    }
}

// Mobile Nav

.mobile-nav {
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    li {
        border-bottom: 1px solid $border-color;
        ul {
            margin-bottom: 15px;
            li {
                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
    a {
        display: block;
        padding: 10px 0 10px 0;
    }
}

// Brands Tabs

.brand-tabs {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    justify-content: center;
    li {
        padding: 0 20px;
        margin-bottom: 20px;
    }
    a {
        font-size: 24px;
        font-weight: 800;
        display: block;
        padding: 14px 20px;
        border: 1px solid $secondary;
        border-radius: $border-radius-lg;
        text-align: center;
        color: $secondary;
        width: 293px;
        margin: 0 auto;
        &:hover,
        &:focus,
        &.active {
            background-color: $secondary;
            text-decoration: none;
            color: #ffffff;
        }
    }
    @include media-breakpoint-up(xl) {
        li {
            padding-left: 50px;
            padding-right: 50px;
        }
    }
}
