//---------------------------------------------------------//
// Images
//---------------------------------------------------------//

// Ratio

.ratio-wrapper {
    position: relative;
    &:before {
        content: "";
        display: block;
        padding-top: 100%;
    }
    > * {
        @include background-image();
    }
    .video-player {
        position: absolute;
    }
}

.ratio4-3 {
    &:before {
        padding-top: calc(3 / 4 * 100%);
    }
}
.ratio16-9 {
    &:before {
        padding-top: calc(9 / 16 * 100%);
    }
}
.ratio16-10 {
    &:before {
        padding-top: calc(10 / 16 * 100%);
    }
}

// Frame

.frame {
    position: relative;
    &:after {
        content: "";
        position: absolute;
        top: 7px;
        right: -7px;
        left: 7px;
        bottom: -7px;
        z-index: 1;
        background-color: $primary;
    }
    img {
        position: relative;
        z-index: 2;
        width: 100%;
    }
}

// Gallery

.gallery {
    display: flex;
    flex-wrap: wrap;
    .gallery__item {
        position: relative;
        width: calc(100% / 2);
        display: block;
        flex-shrink: 0;
        &:before {
            content: "";
            display: block;
            padding-top: 100%;
        }
    }
    .gallery__item-image {
        @include background-image();
    }
    @include media-breakpoint-up(md) {
        .gallery__item {
            width: calc(100% / 4);
        }
    }
}
