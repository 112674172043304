//---------------------------------------------------------//
// Footer
//---------------------------------------------------------//

.footer {
    border-top: 1px solid $gray-white;
    padding: 70px 0;
    font-size: 12px;
    .footer__logo {
        margin-bottom: 30px;
    }
    .footer__title {
        font-size: 14px;
        font-weight: bold;
        color: $secondary;
        margin-bottom: 14px;
    }
    .footer-nav {
        li {
            display: block;
            margin-bottom: 12px;
        }
        a {
            color: $body-color;
            &:hover,
            &:focus {
                color: $primary;
                text-decoration: none;
            }
        }
    }

    .footer__inner {
        margin-bottom: -30px;
    }
    .footer__left,
    .footer__right {
        align-self: center;
        flex-grow: 1;
        margin-bottom: 30px;
    }
    .footer__left {
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
    }
    @include media-breakpoint-down(sm) {
        padding-top: 50px;
        padding-bottom: 50px;
        .acc__toggle {
            padding: 12px 0 12px 0;
            margin: 0;
        }
    }
    @include media-breakpoint-up(md) {
        .acc__toggle {
            background: none !important;
            cursor: auto !important;
            &:after {
                display: none;
            }
        }
        .acc__content {
            display: block !important;
            height: auto !important;
        }
    }
    @include media-breakpoint-up(lg) {
        .footer__inner {
            display: flex;
        }
        .footer__left {
            max-width: 345px;
        }
    }
    @include media-breakpoint-up(xl) {
        .footer__left {
            margin-right: 80px;
        }
    }
}
