// Sick Arrows
.slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
    font-size: 0;
    padding: 15px 20px;
    margin-left: -20px;
    margin-right: -20px;
    &:after {
        content: "";
        display: block;
        width: 12px;
        height: 21px;
    }
}
.slick-next {
    right: 0px;
    &:after {
        background: url("#{$icon-url}/icon-chevron-right.png") no-repeat 0 0;
        background-size: cover;
    }
}
.slick-prev {
    left: 0px;
    &:after {
        background: url("#{$icon-url}/icon-chevron-left.png") no-repeat 0 0;
        background-size: cover;
    }
}

// Slider

.content-slider {
    margin-bottom: $globalMarginBottom;
    &:last-child {
        margin-bottom: 0;
    }
    img {
        max-width: 100%;
    }
    .slick-arrow {
        background-color: #ffffff;
    }
    @include media-breakpoint-up(xxl) {
        .slick-next {
            right: -30px;
        }
        .slick-prev {
            left: -30px;
        }
    }
    @include media-breakpoint-up(md) {
        .slide__inner {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}

// Product Slider

.product-slider {
    margin-bottom: -35px !important;
    .slick-arrow {
        margin-top: 30px;
    }
    .slick-list {
        margin-left: -20px;
        margin-right: -20px;
    }
    .slick-track {
        display: flex;
        .slick-slide {
            height: auto;
        }
    }
    .product-card {
        height: 100%;
        display: flex;
        flex-direction: column;
        .card__header {
            &:after {
                display: none;
            }
        }
    }
    .slide__inner {
        padding-bottom: 50px;
    }
    @include media-breakpoint-up(md) {
        .slide__inner {
            padding-top: 75px;
            padding-bottom: 15px;
            padding-left: 20px;
            padding-right: 20px;
            height: 100%;
        }
        .product-card {
            height: 100%;
            display: flex;
            flex-direction: column;
            .card__header {
                margin-top: -110px;
                &:after {
                    opacity: 1;
                }
            }
            padding: 35px 40px;
            transition: all $easing-speed $easing;
            border-radius: $border-radius-lg;
            &:hover,
            &:focus {
                background-color: #ffffff;
                box-shadow: 0 2px 11px rgba($black, 0.1);
            }
        }
    }
}

// Brands Slider

.brands-slider {
    .slide__inner {
    }
}
