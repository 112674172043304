//---------------------------------------------------------//
// Buttons
//---------------------------------------------------------//
// - All Buttons
//---------------------------------------------------------//

button,
a,
.btn {
    @include transition(
        background-color $easing-speed $easing,
        border-color $easing-speed $easing,
        color $easing-speed $easing
    );
}

button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
}

.btn {
    &.btn-primary {
        color: #ffffff;
    }
    &.btn-outline-primary {
        &:hover,
        &:focus {
            color: #ffffff;
        }
    }
    &.btn-fw {
        min-width: 206px;
    }
    &.btn-fw-lg {
        min-width: 246px;
    }
    &.btn-xl {
        font-size: 14px;
        font-weight: 800;
        padding: $input-btn-padding-y-xl $input-btn-padding-x-xl;
    }
}
