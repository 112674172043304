//---------------------------------------------------------//
// List
//---------------------------------------------------------//

.list {
    list-style: none;
    padding: 0;
    margin: 0 0 $globalMarginBottom 0;
    li {
        display: block;
        margin-bottom: 12px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

// Inline List

.list-inline {
    list-style: none;
    padding-left: 0;
    margin-bottom: rem(-10);
    margin-left: -8px;
    margin-right: -8px;
    li {
        display: inline-block;
        margin-left: rem(8);
        margin-right: rem(8);
        margin-bottom: rem(10);
    }
}

// Bullets

.bullets {
    list-style: none;
    padding-left: 0;
    li {
        position: relative;
        margin-bottom: rem(12);
        display: flex;
        &:before {
            content: "\2022";
            color: $primary;
            flex-shrink: 0;
            padding-right: rem(11);
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}

// Inline Bullets

.inline-bullets {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-flow: row wrap;
    li {
        position: relative;
        margin-bottom: rem(12);
        display: flex;
        &:after {
            content: "\2022";
            flex-shrink: 0;
            padding-left: rem(11);
            margin-right: rem(11);
        }
        &:last-child {
            &:after {
                display: none;
            }
        }
    }
}

// List BOrder

.list-border {
    @extend .nav;
    border-top: 1px solid $border-color;
    li {
        border-bottom: 1px solid $border-color;
        padding: 8px 0;
    }
}

// Info Label

.info-label {
    display: inline-flex;
    font-weight: 600;
    color: $secondary;
    .info-icon {
        color: $primary;
        margin-right: 12px;
    }
    li {
        display: flex;
    }
    .info {
        margin-left: auto;
        text-align: right;
    }
}
