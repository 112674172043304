//---------------------------------------------------------//
// Custom Variables
//---------------------------------------------------------//

$browser-context: 16;
$globalMarginBottom: $spacer-6;

$img-url: "../../images";
$font-url: "../../fonts";
$icon-url: "../../images/icons";

$easing-speed: 0.25s;
$easing: ease-in-out;
