.acc__toggle {
    position: relative;
    cursor: pointer;
    &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -6px;
        background: url("#{$icon-url}/icon-plus.png");
        background-size: contain;
        width: 12px;
        height: 12px;
    }
    &.active {
        &:after {
            background: url("#{$icon-url}/icon-minus.png");
            background-size: contain;
        }
    }
}

.acc__content {
    display: none;
    &.active {
        display: block;
    }
}
