//---------------------------------------------------------//
// Header
//---------------------------------------------------------//

.header {
    top: 0px;
    left: 0;
    width: 100%;
    z-index: 1010;
    position: relative;
    &.header--fixed {
        position: fixed;
        .header__content {
            box-shadow: 0 5px 10px rgba($black, 0.215);
            height: 115px;
        }
    }
    .header__content {
        &.header-hide {
            -webkit-transform: translateY(-115px);
            transform: translateY(-115px);
        }
        &.header-show {
            -webkit-transform: none;
            transform: none;
        }
    }
    @include media-breakpoint-up(md) {
        &.header--fixed {
            .header__content {
                height: 102px;
            }
            .navbar {
                .header__inner {
                    height: 70px;
                }
                .navbar-brand__image {
                    width: 97px;
                    height: 24px;
                }
            }
        }
        .header__content {
            &.header-hide {
                -webkit-transform: translateY(-132px);
                transform: translateY(-132px);
            }
        }
    }
}

.header__content {
    -webkit-transition: transform 0.4s ease-out;
    transition: transform 0.4s ease-out;
}

.header__inner {
    display: flex;
    align-items: center;
    .header__inner-left,
    .header__inner-right {
        align-self: center;
    }
    .header__inner-right {
        margin-left: auto;
    }
}

// Topbar

.topbar {
    background-color: $secondary;
    color: #ffffff;
    font-size: 12px;
    padding: 0;
    height: 30px;
    display: flex;
    align-items: center;
    .y-line {
        background-color: rgba($white, 0.3);
        height: 7px;
    }
    a {
        color: currentColor;
    }
    @include media-breakpoint-down(sm) {
        height: 45px;
        font-size: 11px;
        .header__inner {
            display: block;
        }
        text-align: center;
    }
}

// Navbar

.navbar {
    background-color: $white;
    color: $secondary;
    font-size: 12px;
    a {
        color: currentColor;
    }
    .header__inner {
        height: 70px;
    }
    .navbar__list {
        @extend .nav;
        margin: 0;
    }
    .navbar-brand {
        margin: 0;
    }
    .navbar-col {
        margin-right: 22px;
        display: inline-block;
        vertical-align: middle;
        &:last-child {
            margin-right: 0;
        }
    }
    .navbar-brand__image {
        background: url("#{$img-url}/luxor-logo.png");
        background-size: contain;
        width: 162px;
        height: 40px;
        display: block;
        text-indent: -10000px;
        white-space: nowrap;
        overflow: hidden;
    }
    @include media-breakpoint-up(md) {
        .header__inner {
            height: 100px;
        }
        .navbar-brand__image {
            width: 194px;
            height: 48px;
        }
    }
}

.social-list {
    @extend .nav;
    margin-bottom: -10px;
    margin-left: -4px;
    margin-right: -4px;
    .mobile-menu & {
        .icon-svg {
            width: 24px;
            height: 24px;
        }
    }
    li {
        margin-left: 4px;
        margin-right: 4px;
        margin-bottom: 10px;
        display: inline-block;
    }
    a {
        color: $primary;
        &:hover,
        &:focus {
            color: $secondary;
        }
    }
}

// Mobile Menu

.menu-toggle {
    padding: rem(10);
    position: relative;
    cursor: pointer;
    margin-right: -10px;
    @include media-breakpoint-up(md) {
        display: none;
    }
}

.menu-toggle .icon-bar {
    width: 24px;
    height: 3px;
    background-color: $primary;
    display: block;
    margin: 0 auto 4px auto;
    border-radius: $border-radius;
    &:last-child {
        margin-bottom: 0;
    }
}

.close-toggle {
    position: absolute;
    top: 0;
    right: 0;
    width: rem(50);
    height: rem(50);
    background-color: $primary;
    line-height: rem(50);
    text-align: center;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all $easing-speed $easing;
    transition: all $easing-speed $easing;
    display: none;
    @include media-breakpoint-down(md) {
        display: block;
    }
}

.mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: transform 0.5s cubic-bezier(1, 0, 0, 1);
    transition: transform 0.5s cubic-bezier(1, 0, 0, 1);
    z-index: 1001;
    .mobile-menu-inner {
        padding: rem(20);
        background-color: $white;
        position: fixed;
        right: rem(50);
        left: 0;
        height: 100%;
        width: auto;
        display: block;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        box-shadow: 0 0 50px rgba($black, 0.115);
    }
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.menu-open {
    @include media-breakpoint-down(md) {
        &:after {
            opacity: 1;
            visibility: visible;
            z-index: 22;
        }
        .mobile-menu {
            -webkit-transform: none;
            transform: none;
        }
        .close-toggle {
            opacity: 1;
            visibility: visible;
        }
    }
}
