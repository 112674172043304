.card {
    background-color: $gray-white;
    &.card-plain {
        background: none;
    }
    img {
        max-width: 100%;
    }
    .card__header {
        display: block;
        margin-bottom: 40px;
        position: relative;
        img {
            margin: 0 auto;
            position: relative;
            z-index: 2;
        }
    }
    .card__body {
        padding: 35px;
    }
    h1,
    h2,
    h3,
    h4,
    .h1,
    .h2,
    .h3,
    .h4,
    p {
        margin-bottom: 12px;
    }
    .btn {
        margin-top: 12px;
    }
}

// Product Card

.product-card {
    text-align: center;
    padding: 0 50px;
    background: none;
    .card__body {
        padding: 0;
    }
    .card__header {
        &:after {
            content: "";
            background: url("#{$img-url}/product-shadow.png") no-repeat 50% 0;
            position: absolute;
            bottom: 50px;
            left: -40px;
            right: -40px;
            height: 61px;
            opacity: 0.6;
        }
        img {
            max-height: 319px;
        }
    }
    @include media-breakpoint-down(sm) {
        .card__header {
            &:after {
                background-size: 280px 45px;
            }
        }
    }
    @include media-breakpoint-between(md, lg) {
        .card__header {
            &:after {
                background-size: 250px 40px;
            }
        }
    }
    @include media-breakpoint-up(lg) {
        .card__header {
            img {
                max-height: 390px;
            }
        }
    }
}

.product-grid {
    margin-bottom: -70px;
    .product-card {
        margin-bottom: 70px;
    }
}
