//---------------------------------------------------------//
// Main
//---------------------------------------------------------//

.container-inner {
    max-width: 1060px;
    margin: 0 auto;
}

// Sections

.section {
    padding: 95px 0;
    &.section--small {
        padding: 50px 0;
    }
    .section__border-bottom {
        border-bottom: 1px solid $primary;
        padding-bottom: 95px;
        margin-bottom: 95px;
        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }
    }
    @include media-breakpoint-down(sm) {
        padding: 50px 0;
        .section__border-bottom {
            border-bottom: 1px solid $primary;
            padding-bottom: 50px;
            margin-bottom: 50px;
        }
    }
}

.section-header {
    max-width: 800px;
    margin: 0 auto 40px auto;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
    @include media-breakpoint-down(sm) {
        margin-bottom: 30px;
    }
}

.section-margin {
    margin-bottom: 40px;
    &:last-child {
        margin-bottom: 0;
    }
    &.section-margin--large {
        margin-bottom: 50px;
    }
    @include media-breakpoint-up(md) {
        margin-bottom: 60px;
        &.section-margin--large {
            margin-bottom: 80px;
        }
    }
}

// Brands

.brands {
    position: relative;
    &:before {
        content: "";
        background-color: $primary;
        position: absolute;
        top: 0;
        left: 0;
        height: 400px;
        width: 100%;
        z-index: -1;
    }
    @include media-breakpoint-up(md) {
        &:before {
            height: 420px;
        }
    }
    @include media-breakpoint-up(xl) {
        &:before {
            height: 491px;
        }
    }
}

.brand-logo {
    background-color: $gray-white;
    height: 115px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius;
    img {
        max-width: 187px !important;
        max-height: 85px;
    }
}

.logo-fh {
    min-height: 72px;
}

// Featured Testimonial

.featured-testimonial {
    background-color: $gray-white;
    padding: 30px 0;
    margin-bottom: 50px;
    blockquote {
        margin: 0;
    }
    .featured-testimonial__image {
        margin-top: -55px;
        margin-bottom: 30px;
    }
    @include media-breakpoint-up(lg) {
        margin-top: 25px;
        margin-bottom: 60px;
        .featured-testimonial__image {
            margin-left: 30px;
            margin-right: 30px;
            margin-bottom: -55px;
        }
    }
    @include media-breakpoint-up(xl) {
        .featured-testimonial__image {
            margin-left: 60px;
            margin-right: 60px;
        }
    }
}

// Column Bordered

.column-bordered {
    .column-bordered__inner {
        border-bottom: 1px solid rgba($primary, 0.5);
        padding-bottom: 30px;
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border: none;
        }
    }
    .column-bordered__text {
        max-width: 350px;
        margin: 0 auto;
        text-align: center;
    }
    @include media-breakpoint-up(md) {
        display: flex;
        margin-left: -20px;
        margin-right: -20px;
        .column-bordered__inner {
            width: 50%;
            border-right: 1px solid rgba($primary, 0.5);
            padding-bottom: 0;
            border-bottom: none;
            padding-right: 20px;
            padding-left: 20px;
            margin-bottom: 0;
            &:last-child {
                border-right: none;
            }
        }
    }
}

// Products

.product-description {
    text-align: center;
}
.product-logo {
    margin-bottom: 65px;
}
.product-image {
    text-align: center;
    img {
        margin: 0 auto;
        display: block;
        max-width: 100%;
    }
}

.product-info {
    width: 390px;
    margin: 0 auto;
}

// Feature Row

.feature-row {
    display: flex;
    .feature-row__item {
        align-self: center;
        width: 50%;
        text-align: center;
    }
    @include media-breakpoint-down(sm) {
        flex-direction: column;
        .feature-row__item {
            width: 100%;
        }
        .feature-row__image {
            order: 1;
        }
        .feature-row__text {
            order: 2;
            padding-top: 50px;
        }
    }
    @include media-breakpoint-up(md) {
        .feature-row__item {
            padding-right: 30px;
            &:last-child {
                padding-left: 30px;
            }
        }
    }
    @include media-breakpoint-up(xl) {
        .feature-row__item {
            padding-right: 45px;
            &:last-child {
                padding-left: 45px;
            }
        }
    }
}
