//---------------------------------------------------------//
// Base
//---------------------------------------------------------//

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

// Vertical Line

.y-line {
    width: 1px;
    background-color: #d6d6d6;
    display: inline-block;
    height: 15px;
    vertical-align: middle;
    margin: 0;
}

.x-divider {
    width: 40px;
    height: 3px;
    display: block;
    margin-bottom: $globalMarginBottom;
    background-color: $primary;
    .text-center & {
        margin-left: auto;
        margin-right: auto;
    }
}

// Background Colors

.bg-white {
    background-color: $white;
}
.bg-light {
    background-color: $gray-white;
}

// Column Bordered

.column-bordered {
    > .col,
    > [class*="col-"] {
        border-right: 1px solid rgba($primary, 0.5);
    }
}

// Grid Column

.grid-column,
.grid-column-large {
    .thumbnail,
    .panel,
    .card {
        margin-bottom: 0;
    }
    &.section-margin {
        margin-bottom: 30px;
        > .col,
        > [class*="col-"] {
            margin-bottom: 30px;
        }
        &.section-margin--large {
            margin-bottom: 40px;
            > .col,
            > [class*="col-"] {
                margin-bottom: 40px;
            }
        }
    }
}

.grid-column {
    margin-bottom: -$grid-gutter-width;

    > .col,
    > [class*="col-"] {
        margin-bottom: $grid-gutter-width;
    }
}

.grid-column-large {
    margin-bottom: -50px;
    > .col,
    > [class*="col-"] {
        margin-bottom: 50px;
    }
}

// Gutters

.gutter-sm {
    margin-left: -10px;
    margin-right: -10px;
    > .col,
    > [class*="col-"] {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.gutter-lg {
    @include media-breakpoint-up(lg) {
        margin-left: -35px;
        margin-right: -35px;
        > .col,
        > [class*="col-"] {
            padding-left: 35px;
            padding-right: 35px;
        }
    }
}
