//---------------------------------------------------------//
// TYPOGRAPHY
//---------------------------------------------------------//
// - All types of typography
// - Font-face
// - Headings
// - Paragraphs
//---------------------------------------------------------//

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-top: 0;
    margin-bottom: $globalMarginBottom;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    a {
        color: inherit;
        &:hover,
        &:focus {
            color: $link-hover-color;
            text-decoration: none;
        }
    }
}

.text-link {
    color: $gray;
    &:hover,
    &:focus {
        color: $gray-darker;
    }
}

.hr-white {
    border-color: $white;
}

// Font Weight

.font-weight-medium {
    font-weight: 500 !important;
}
.font-weight-semi {
    font-weight: 600 !important;
}
.font-weight-extra {
    font-weight: 800 !important;
}

// Line Heights

.line-height-sm {
    line-height: $line-height-sm;
}
.line-height-xs {
    line-height: $line-height-xs;
}
.line-height-lg {
    line-height: $line-height-lg;
}

// Letter Spacing

.letter-spacing-1 {
    letter-spacing: 1px;
}
.letter-spacing-2 {
    letter-spacing: 2px;
}
.letter-spacing-3 {
    letter-spacing: 2px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    &.h1,
    &.h2,
    &.h3,
    &.h4,
    &.h5,
    &.h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        line-height: $headings-line-height;
    }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    line-height: $line-height-base;
    strong {
        font-weight: 800;
    }
}

strong {
    &.h1,
    &.h2,
    &.h3,
    &.h4,
    &.h5,
    &.h6 {
        font-weight: 800;
    }
}
h1,
h2,
h3,
h4,
h5,
h6 {
    strong {
        font-weight: 800;
    }
}

// Heading

h1,
.h1 {
    font-size: 32px;
    @include media-breakpoint-up(md) {
        font-size: 36px;
    }
}

h2,
.h2 {
    font-size: 28px;
    @include media-breakpoint-up(md) {
        font-size: 32px;
    }
}

h3,
.h3 {
    font-size: 24px;
    @include media-breakpoint-up(md) {
        font-size: 28px;
    }
}

h4,
.h4 {
    font-size: 20px;
    @include media-breakpoint-up(md) {
        font-size: 24px;
    }
}

h5,
.h5 {
    font-size: 18px;
    @include media-breakpoint-up(md) {
        font-size: 20px;
    }
}

h6,
.h6 {
    font-size: 16px;
}

// Display Sizes

.display-1 {
    font-size: 36px;
    @include media-breakpoint-up(md) {
        font-size: 46px;
    }
    @include media-breakpoint-up(lg) {
        font-size: 56px;
    }
}

// Text Sizes

.text-tiny {
    font-size: 10px;
}
.text-sm {
    font-size: 12px;
}
.text-md {
    font-size: 14px;
}

p {
    &.text-sm,
    .text-sm & {
        line-height: 2;
    }
}

// Text Colors

.text-white {
    color: $white !important;
}

// Link

.text-link {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 800;
    position: relative;
    line-height: 1;
    color: $secondary;
    &:after {
        content: "";
        position: absolute;
        bottom: 1px;
        left: 0;
        width: 100%;
        border-bottom: 1px solid currentColor;
    }
    &:hover,
    &:focus {
        text-decoration: none;
        color: $primary;
    }
}

// RTE

h1,
h2,
h3,
h4,
h5,
h6,
p {
    &:last-child {
        margin-bottom: 0;
    }
}

p + .btn {
    margin-top: 12px;
}

.rte {
    margin-bottom: $globalMarginBottom;
    &:last-child {
        margin-bottom: 0;
    }
}

// Blockquote

blockquote {
    .quote {
        position: relative;
        padding: 18px 0 0 35px;
        font-size: 23px;
        font-weight: 600;
        text-transform: uppercase;
        color: $secondary;
        line-height: 1.5;
        &:before,
        &:after {
            content: "";
            position: absolute;
            width: 20px;
            height: 18px;
        }
        &:before {
            background: url("#{$icon-url}/icon-quote-open.png");
            background-size: contain;
            left: 0;
            top: 0;
        }
        &:after {
            background: url("#{$icon-url}/icon-quote-close.png");
            background-size: contain;
            right: 0;
            bottom: 10px;
        }
    }
    .author {
        text-align: right;
    }
    .author__name {
        display: block;
        font-weight: 600;
        margin-bottom: 0px;
        color: $secondary;
    }
    .author__desc {
        display: block;
        font-size: 10px;
    }
}
