.dropdown-menu {
    font-size: 12px;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-5px);
    transition: all 0.25s ease-in-out;
    display: block;
    .dropdown-item {
        border-radius: 15px;
    }
}

.dropdown:hover .dropdown-menu,
.dropdown.active .dropdown-menu {
    opacity: 1;
    visibility: visible;
    transform: none;
}

// Brands Menu

.brands-menu {
    border-radius: 20px;
    padding: 10px;
    border: none;
    box-shadow: 0 0 10px rgba($black, 0.15);
    min-width: 315px;
    .dropdown-item {
        display: flex;
        align-items: center;
        .icon-image {
            transition: all $easing-speed $easing;
        }
        &:hover,
        &:focus {
            .icon-image-mug {
                background: url("#{$icon-url}/icon-mug-secondary.png") 0 0 no-repeat;
                background-size: 33px 35px;
            }
            .icon-image-cocktails-glass {
                background: url("#{$icon-url}/icon-cocktails-glass-secondary.png") 0 0 no-repeat;
                background-size: 24px 35px;
            }
            .icon-image-wine-glass {
                background: url("#{$icon-url}/icon-wine-glass-secondary.png") 0 0 no-repeat;
                background-size: 30px 35px;
            }
        }
    }
    .icon-circle {
        margin-bottom: 0;
        margin-right: 30px;
    }
}
