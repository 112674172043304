//---------------------------------------------------------//
// Hero
//---------------------------------------------------------//

.hero {
    height: 450px;
    position: relative;
    color: #ffffff;
    background-color: $secondary;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        color: inherit;
    }
    .hero__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
    }
    .hero__text {
        position: relative;
        z-index: 2;
        width: 100%;
        height: 100%;
    }
    .container {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
    }
    .row {
        width: 100%;
    }
    .hero__beer {
        position: absolute;
        bottom: 0;
        display: none;
    }
    .hero__beer-1 {
        right: 152px;
        bottom: -30px;
        z-index: 2;
    }
    .hero__beer-2 {
        right: 0px;
        bottom: -20px;
    }
    &.hero-inner {
        background-color: $primary;
        height: 185px;
        .container {
            justify-content: center;
        }
    }
    @include media-breakpoint-down(sm) {
        text-align: center;
    }
    @include media-breakpoint-up(md) {
        height: 578px;
        .hero__beer {
            display: block;
        }
    }
    @include media-breakpoint-up(lg) {
        .hero__beer-1 {
            right: 237px;
        }
        .hero__beer-2 {
            right: 85px;
        }
    }
    @include media-breakpoint-up(xl) {
        .hero__beer-1 {
            right: 337px;
        }
        .hero__beer-2 {
            right: 185px;
        }
    }
}
