//---------------------------------------------------------//
// Forms
//---------------------------------------------------------// 


.form-control, input, textarea {
    &.error {
        border-color: $alert !important;
    }
}

label.error {
    color: $alert;
    margin-top: 3px;
    font-size: 14px;
}

select {
    background-image: url(#{'"{{ "icon-select-arrow.png" | asset_url }}"'}) !important;
    background-size: 10px 7px !important;
    background-position: right $input-btn-padding-x center !important;
    background-repeat: no-repeat !important;
    padding-left: $input-btn-padding-x !important;
    padding-right: $input-btn-padding-x + 0.875rem !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
    &:focus {
        color: $black;
    }
}


// success/error container

.note {
    border-radius: $border-radius;
}


// Form Errors

.errors {
    color: $danger;
    font-size: rem(12);
    display: block;
    background-color: rgba($danger, 0.15);
    padding: 15px;
    border: 1px solid $danger;
    border-radius: $border-radius;
    margin-top: 15px;
    &.qty-error {
        margin-bottom: 10px;
    }
    @include no-margin-last();
}



.form-message--error {
    border-radius: $border-radius;
    h1, h2, h3, h4, h5, h6, ul, ol, p {
        &:last-child {
            margin-bottom: 0;
        }
    }
}



// FOrm Row

.form-row {
    margin-right: -10px;
    margin-left: -10px;
    > .col,
    > [class*="col-"] {
        padding-right: 10px;
        padding-left: 10px;
    }
}