//---------------------------------------------------------//
// Icons
//---------------------------------------------------------//
// - All Icons
//---------------------------------------------------------//

.icon-image,
.icon {
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// Icon Sizes

.icon-sm {
    font-size: rem(14);
}
.icon-md {
    font-size: rem(16);
}
.icon-lg {
    font-size: rem(18);
}

// Icon Margin

.icon-margin-right {
    margin-right: rem(12);
}
.icon-margin-left {
    margin-left: rem(12);
}

// Icon SVG

.icon-svg {
    width: 22px;
    height: 22px;
    path {
        fill: currentColor;
    }
}

.icon-hover {
    .icon-svg {
        path {
            fill: $body-color;
        }
    }
    &:hover,
    &:focus {
        .icon-svg {
            path {
                fill: $primary;
            }
        }
    }
}

// Icon Content

.icon-circle {
    width: 65px;
    height: 65px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: $gray-white;
    margin-bottom: $globalMarginBottom;
    img {
        max-height: 35px;
    }
    &.icon-circle-lg {
        width: 178px;
        height: 178px;
        img {
            max-height: 90px;
        }
    }
}

// Icon Images

.icon-image-close {
    background: url("#{$icon-url}/icon-close-white.png") 0 0 no-repeat;
    @include background-retina(14, 14);
}

.icon-image-mug {
    background: url("#{$icon-url}/icon-mug.png") 0 0 no-repeat;
    @include background-retina(33, 35);
}
.icon-image-cocktails-glass {
    background: url("#{$icon-url}/icon-cocktails-glass.png") 0 0 no-repeat;
    @include background-retina(24, 35);
}
.icon-image-wine-glass {
    background: url("#{$icon-url}/icon-wine-glass.png") 0 0 no-repeat;
    @include background-retina(30, 35);
}
