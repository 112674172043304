//---------------------------------------------------------//
// Override Boostrap Variables
//---------------------------------------------------------//

// Colors

$gray-white: #f7f7f7;
$gray-light: #cccccc;
$gray-lighter: #eeeeee;
$gray: #9f9f9f;
$gray-dark: #47494b;
$gray-darker: #323232;

$black: #000000;
$white: #ffffff;
$alert: #ffc11a;

$primary: #fcc90c;
$secondary: #360f41;
$tertiary: #8a1948;

$theme-colors: () !default;
$theme-colors: map-merge(
    (
        "default": $black,
        "tertiary": $tertiary,
    ),
    $theme-colors
);

// Grid - Breakpoints - Container

$container-xl: 1200px;

$grid-gutter-width: 40px;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: $container-xl + $grid-gutter-width,
);

$spacer: 1rem !default;
$spacer-1: 0.3125rem; // 5px
$spacer-2: 0.625rem; // 10px
$spacer-3: 0.9375rem; // 15px
$spacer-4: 1.25rem; // 20px
$spacer-5: 1.563rem; // 25px
$spacer-6: 1.875rem; // 30px
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
    (
        0: 0,
        1: $spacer-1,
        2: $spacer-2,
        3: $spacer-3,
        4: $spacer-4,
        5: $spacer-5,
        6: $spacer-6,
    ),
    $spacers
);

// Global

$body-bg: #ffffff;
$body-color: #adadad;

$text-muted: #adadad;

$border-color: $gray-lighter;

$link-color: $primary;
$link-hover-color: darken($primary, 10%);
$link-hover-decoration: underline;

// Typography

$font-size-base: 1rem;
$font-size-lg: 1.125rem;
$font-size-md: 0.875rem;
$font-size-sm: 0.75rem;
$line-height-base: 1.68;
$line-height-sm: 1.3;
$line-height-xs: 1.1;
$line-height-lg: 2;

$font-family-base: "Open Sans", sans-serif;
$font-family-secondary: "bebas_neue", sans-serif;

$headings-font-weight: normal;
$headings-color: $secondary;
$headings-font-family: $font-family-base;
$headings-line-height: 1.2;

$hr-border-color: $gray-lighter;
$hr-margin-y: 1.875rem;

$lead-font-size: 18px;
$lead-font-weight: normal;

$display1-weight: inherit;
$display2-weight: inherit;
$display3-weight: inherit;
$display4-weight: inherit;

$display-line-height: 1;

// Components - Padding

$border-radius: 0.1875rem;
$border-radius-lg: 0.3125rem;
$border-radius-sm: 0.1875rem;

$input-btn-padding-y: 0.875rem;
$input-btn-padding-x: 1.375rem;

$input-btn-padding-y-lg: 1.125rem;
$input-btn-padding-x-lg: 1.375rem;

$input-btn-padding-y-xl: $input-btn-padding-y-lg + 0.3125rem;
$input-btn-padding-x-xl: $input-btn-padding-x-lg;

$label-margin-bottom: 0.58rem;

// Buttons

$btn-font-weight: 600;
$btn-font-size: 0.75rem;
$btn-font-size-lg: 0.875rem;

// Forms

$form-group-margin-bottom: 0.875rem;

$input-color: $gray;
$input-border-color: $gray-white;
$input-placeholder-color: #adadad;
$input-bg: $gray-white;
$input-font-weight: 600;
$input-line-height: 1.4;
$input-height: 50px;

// DRopdown

$dropdown-link-color: $body-color;
$dropdown-link-hover-color: $body-color;
$dropdown-link-hover-bg: #fff9e6;

$dropdown-item-padding-y: 0.5rem;
$dropdown-item-padding-x: 0.9375rem;
